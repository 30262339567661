@tailwind base;
@tailwind components;
@tailwind utilities;

/* Incosolata font: https://fonts.google.com/specimen/Inconsolata?query=incon#standard-styles */

html {
  &[data-theme='homeroom'] {
    @apply bg-gradient-to-t from-ash-300 to-ash-500 bg-no-repeat bg-fixed h-full font-serif;
  }

  &[data-theme='explorer'] {
    @apply bg-gradient-to-t to-ultramarineBlue-900 from-wash-800 bg-no-repeat bg-fixed h-full font-sans;
  }
}

body {
  @apply h-full text-primary;
}

#__next {
  @apply w-full h-full m-0;
}

h1 {
  @apply text-3xl;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-xl;
}

h4 {
  @apply text-lg;
}

h5 {
  @apply text-base;
}

h6 {
  @apply text-sm;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  span {
    @apply text-icedNeon-500;
  }
}

ol {
  li {
    @apply list-decimal list-inside;
  }
}
.list-style-disc {
  @apply list-disc pl-4;
}

ul {
  .list-style-custom {
    @apply flex;

    &:not(.list-none) {
      &:before {
        content: '\2022';
        @apply flex mx-3 text-primary;
      }

      &:first-of-type {
        &:before {
          @apply hidden;
        }
      }
    }
  }
}

button {
  @apply rounded-md transition-all;

  &[data-active='true'] {
    @apply bg-accent text-neutral font-bold;

    &:hover {
      @apply bg-accent-focus;
    }

    a {
      @apply text-neutral;
    }
  }
}

textarea,
input[type='text'] {
  @apply w-full p-4 my-2 rounded-md bg-ash-500 text-wash-500;
}

input[type='submit'] {
  @apply bg-ash-500 text-wash-500 mt-4 mr-4 py-2 px-4 rounded-md;
}

a {
  @apply text-primary;
}

@layer components {
  .link {
    @apply text-secondary underline transition-colors duration-300;

    &:hover {
      @apply text-accent;
    }

    &[data-active='true'] {
      @apply text-accent;
    }
  }

  .errorText {
    @apply m-4 text-crayolaRed-500;
  }
}
